(function() {
    'use strict';

    // ? VARIABLES 
    var cerrado     = true;
    var menuLat     = false;

    let promociones = [];
    let heros = [];

    //! REPRPDUCCIÓN DE VIDEO
    var $vtnaVideo = $('#ventanaVideo');
    var $iframeVideo = $('#iframeVideo');

    // let idxEq = 0;

    document.addEventListener('DOMContentLoaded', function(){

        if ($vtnaVideo !== null) {

            $vtnaVideo.on('hidden.bs.modal', function (event) {
                $iframeVideo.attr('src',null);
            });
        }

        iniciarClicks();
    });

    function iniciarClicks() {
            
        document.addEventListener('click', (e) => {
            e.preventDefault();
            clicksWeb(e.target);
        });

        window.addEventListener('scroll', function() {
            navBackground();
        })
        navBackground();

        if (document.getElementById("banners_equipo")) {
            promociones = document.querySelectorAll('.paquete_hardware')            
            setInterval(next_promo, 10000); 
        }

        if (document.getElementById("fondosHero")) {
            heros = document.querySelectorAll('.fondo_hero')
            setInterval(next_fondo, 2500); 
        }
        
    }


    // ! FUNCIÓN PARA ACTIVAR EL VIDEO Y REPRODUCIRLO AL ESTAR LA PANTALLA ACTIVA
    function toggleVideo() {
        const trailer = document.querySelector('.videoPopup');
        const video = document.querySelector('video');

        trailer.classList.toggle('active');
        video.currentTime = 0;
        if (trailer.classList.contains('active')) {
            video.play();            
        } else {
            video.pause();
        };
    }    


    // ! FUNCIÓN PARA ACTIVAR TODOS LOS CLICS DE LA PÁGINA
    function clicksWeb(objetoDom) {

        // console.log(objetoDom);

        if ( objetoDom.matches('.opCard') || objetoDom.matches('.clCard')) {
            rotarCard(objetoDom);
        }

        if ( objetoDom.matches('.btnFaq') || objetoDom.matches('.accordion-title') || objetoDom.matches('.iconFaq') ) {
            toggleAccordion(objetoDom);            
        }

        // ? BOTÓN PARA REPRODUCIR EL VÍDEO PROMOCIONAL
        if ( objetoDom.matches('#play') || objetoDom.matches('#close') || objetoDom.matches('#imgPlay') ) {
            toggleVideo();
        }

        // ? BOTÓN LATERAL PARA SUBIR AL INICIO DE LA PANTALLA
        if ( objetoDom.matches('#botInicio') ) {

            window.scrollTo({top: 0, behavior: 'smooth'});

            // Este código es con JQUERY
            // $('html, body').animate({
            //    scrollTop: 0
            // }, 100);        
        }

        // ? LINK A PAGINA DE INICIO
        if ( objetoDom.matches('.mnuInicio') || objetoDom.matches('#logoBDK')) {
            let url = 'index.html';
            window.open(url,'_self');
        }            

        // ? LINK A CONTENIDO
        if ( objetoDom.matches('.mnuContenido')) {
            scroll_to('sec_contenido');
        }            

        // ? LINK A GALERÍA
        if ( objetoDom.matches('.mnuGaleria')) {
            scroll_to('sec_galeria');
        }

        // ? LINK A PRECIOS
        if ( objetoDom.matches('.mnuPrecios')) {
            scroll_to('sec_precios');
        }            

        // ? LINK A AGENDA
        if ( objetoDom.matches('.mnuAgenda')) {
            scroll_to('sec_agenda');
        }            

        // ? LINK A TURISMO
        if ( objetoDom.matches('.mnuTurismo')) {
            scroll_to('sec_turismo');
        }            

        // ? LINK A CONTACTO
        if ( objetoDom.matches('.mnuContacto')) {
            scroll_to('footer');
        }            

        // ? LINK A PAGINA DE BDKSISTEMAS
        if ( objetoDom.matches('.enlaceBDK')) {
            let url = 'https://www.bdksistemas.com.mx/';
            window.open(url);
        }            

        // ? BOTÓN PARA CERRAR LOS POPUP INFORMATIVOS
        if (objetoDom.matches('#close')) {
            const popup = document.querySelector('.infoPopup');
            popup.classList.toggle('active');            
        }

        // ? LINK A WHATSAPP DE VENTAS
        if ( objetoDom.matches('.botWP')) {
            let url = 'https://api.whatsapp.com/send?phone=528118222666&text=Hola,%20buen%20día,%20%20quiero%20pedir%20informes%20de%20la%20quinta';
            window.open(url, '_blank');
        } 


        // ? LINKS A FACEBOOK
        if ( objetoDom.matches('.botFB')) {               
            let url = 'https://www.facebook.com/QuintaLosRodriguezAramberri';            
            window.open(url, '_blank');
        } 

        // ? LINKS A FACEBOOK
        if ( objetoDom.matches('.botResenas')) {               
            let url = 'https://www.facebook.com/QuintaLosRodriguezAramberri/reviews/';
            window.open(url, '_blank');
        } 

        // ? BOTÓN REGRESAR
        if ( objetoDom.matches('#regresar')) { 
            window.history.back();
        } 

        // ? ICONO DEL MENÚ HAMBURGUESA
        if ( objetoDom.matches('#botApp')) { 
            
            menuLat = true;
            apertura();
        }

        // ? BOTÓN PARA VER LA GALERIA EXTERIOR
        if (objetoDom.matches('#botexterior')) {
            activarGaleria(0);
        }
        // ? BOTÓN PARA VER LA GALERIA INTERIOR
        if (objetoDom.matches('#botinterior')) {
            activarGaleria(1);
        }
        // ? BOTÓN PARA VER LA GALERIA OTRAS
        if (objetoDom.matches('#bototras')) {
            activarGaleria(2);
        }

        // ? CLICK EN LOS ENLACES PARA VER LOS VIDEOS INFORMATIVOS
        if ( objetoDom.matches('.urlVideo')) {

            reproduce_video(objetoDom);    
            
        }

    }

    function activarGaleria(galeria) {

        let botones = document.querySelectorAll('.botgalery');
        let galeriaSel = document.querySelectorAll('.galeria_content');

        for (let i = 0; i < botones.length; i++) {

            if (i === galeria) {
                botones[i].classList.remove('botonGris')
                botones[i].classList.add('boton')
                galeriaSel[i].classList.add('galactive');
            } else {
                botones[i].classList.remove('boton')
                botones[i].classList.add('botonGris')
                galeriaSel[i].classList.remove('galactive');
            }
           
        }


        // let botInterior = document.querySelector('#botinterior');
        // let botOtras = document.querySelector('#bototras');

        // let galExterior = document.querySelector('.galeria_exterior');
        // galExterior.classList.toggle('galactive');

        // let galInterior = document.querySelector('.galeria_interior');
        // galInterior.classList.toggle('galactive');
        
        // let galOtras = document.querySelector('.galeria_otras');
        // galOtras.classList.toggle('galactive');

    }


    // ! FUNCIÓN PARA ABRIR EL MENÚ CUANDO EL TAMAÑO SEA TABLET O MÓVIL
    function apertura() {
        let menu    = document.getElementById('navPrincipal');
        let botSubir = document.getElementById('botInicio');
        let botAccWP	 = document.getElementById('botWP');
        
        if (cerrado) {
            menu.style.width = '100vw!important';
            cerrado = false;
            
            if (botSubir) {
                botSubir.style.visibility = 'hidden';
                botAccWP.style.visibility = 'hidden';
            }

        } else {
            menu.style.width = '0%';
            menu.style.overflow = 'hidden';
            cerrado = true;
            menuLat = false;
            if (botSubir) {
                botSubir.style.visibility = 'visible';
                botAccWP.style.visibility = 'visible';
            }
        }
        menu.classList.toggle('menu2');
    };   


    
    function navBackground() {

        let nav = document.querySelector('.nav');
        let desp_actual = window.pageYOffset;
        let ancho_screen = window.innerWidth;        

        nav.style.borderBottom = ''; 
        nav.style.background = 'linear-gradient(0deg, rgba(0,0,0,0) 5%, rgba(0,0,0,1) 100%)';
        nav.style.transition = '.5s';
    }

    function next_fondo() {
        let idxHero = actBanner(heros, 0);      
        nextBanner(heros, idxHero, '+');        
    }

    function next_promo() {      
        let idxPromo = actBanner(promociones, 0);      
        nextBanner(promociones, idxPromo, '+');        
    }

    function back_promo() {      
        let idxPromo = actBanner(promociones, 0);      
        nextBanner(promociones, idxPromo, '-');
    }

    function actBanner(array, idxBan) {
        while (idxBan < array.length) {
            if (array[idxBan].classList.contains('banner_visible')) {
                break;
            }
            idxBan++;
        }
        return idxBan;
    }

    function nextBanner(array, idx, opr) {
        let idxNext = idx;
        if (opr==='+') {
            idxNext++;
            if (idxNext > array.length - 1) {
                idxNext = 0;
            }    
        } else {
            --idxNext;
            if (idxNext < 0) {
                idxNext = array.length - 1;
            }
        }
        array[idx].classList.remove('banner_visible');
        array[idxNext].classList.add('banner_visible');
    }

    // ! FUNCIÓN PARA MOVERSE A UNA SECCIÓN DE LA PÁGINA
    function scroll_to(id_elemento) {
        let ancho_screen = window.innerWidth;        
        if (ancho_screen <= 768 && cerrado===false) {
            apertura();
        }
        location.hash = '#' + id_elemento;
    }    

    // ! FUNCIÓN PARA REPRODUCIR UN VÍDEO 
    function reproduce_video( obj ) {
        
        let idvid = $(obj).attr('idvid');
        let tipvid = $(obj).attr('tipo');
        
        // let nomFile = tipvid === "reseña" ? "video_testimonios" : "videos_pagina";
        let nomFile = "videos_pagina";
        let fileJSON = '../../assets/' + nomFile + '.json';
            
        fetch(fileJSON)
            .then(response => response.json())
            .then(data => {
        
                // Buscar el registro con tipo="pagina" y clave="01"
                let video = data.videos.find(function(item) {
                    return item.idvid === idvid;
                });
                
                const $modTitulo = $('#mod-titulo');
                const $modDesc = $('#mod-desc');
        
                $modTitulo.text(video.titulo);
                $modDesc.text(video.descrip);

                if (tipvid !== "reseña") {

                //     let vportada = document.getElementById('portada_video');
                //     vportada.innerHTML = `
                //         <picture>
                //             <source srcset="build/img/vportadas/${video.idvid}.avif" type="image/avif">
                //             <source srcset="build/img/vportadas/${video.idvid}.webp" type="image/webp">
                //             <img width="350" height="150" src="build/img/vportadas/${video.idvid}.jpg" alt="Portada del vídeo en pantalla">
                //         </picture>

                //         <a href="#myModal" class="play urlVideo"><img width="100" height="100" src="build/img/play.png" alt="Dale PLAY al vídeo" class="urlVideo" idvid="${video.idvid}" tipo="${video.tipo}"></a>
                //     `;
                }

                $iframeVideo.attr('src',video.link+"?autoplay=1");
                $vtnaVideo.modal('show');
            
            })
        .catch(error => {
            console.log('Error al cargar el archivo JSON:', error);
        });

    }

    
})();